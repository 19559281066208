.header{
  position: absolute;
  text-align: center;
  background-color: rgba(238 , 64, 86, 0.5);
  width: 100%;
  height: 3.5em;
  margin: auto;
  margin-top: 0px;
}

.links{
  color: white;
  text-align: center;
  overflow: hidden;
  margin: auto;
  float: left;
  font-size: min(4.8vw, 40px);
  -moz-transition: all 280ms ease;
  -o-transition: all 280ms ease;
  -webkit-transition: all 280ms ease;
  transition: all 280ms ease;
}

.links + .links {
  margin: 0 0 0 12px;
}

.lang {
  text-align: right;
}

.footer {
  position: absolute;
  overflow: hidden;
  bottom: 0;
  right: 0%;
  width: 25vw;
  text-align: left;
  align-items: flex-end;
  font-size:min(2vw, 18px);
}

.indent{
  margin-left: 1.5%;
}

.head {
  padding: 10px;
  margin: auto;
  border: none;
  text-decoration: none;
  color: #fff;
}

.box {
  margin       : auto;
  margin-top: 3.5em;
  width        : 70%;
  height: 100%;
  background-color: rgba(255 , 255, 255, 0.9);
}

.namiko {
  text-align: center;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: min(11vw, 50px + 2vmin);
  color: white;
}
a:visited{
  color: inherit;
}

.App-link {
  color: #61dafb;
}


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  color: inherit;
  vertical-align: baseline;
  font-family: 'Arial','ＭＳ Ｐゴシック',sans-serif;
}

html {
  line-height: 1;
  width: auto;
  height: 100%;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a {
  border: none;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

body {
  background: #fff;
  background-image: url(/images/flowers.jpg);
}

.sns_box {
  position: absolute;
  text-align: center;
  overflow: hidden;
  top: 14%;
  left: 50%;
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin: auto;
  height: 44px;
  min-width: 268px;
}

.sns_button {
  float: left;
  box-shadow: inset 0 0 0 2px #fff;
  border-radius: 100%;
  -moz-transition: all 280ms ease;
  -o-transition: all 280ms ease;
  -webkit-transition: all 280ms ease;
  transition: all 280ms ease;
}
.sns_button a {
  display: table-cell;
  width: 2.7em;
  height: 2.7em;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -moz-transition: all 280ms ease;
  -o-transition: all 280ms ease;
  -webkit-transition: all 280ms ease;
  transition: all 280ms ease;
}
.sns_button i {
  font-size: 20px;
  vertical-align: middle;
}
.sns_button:hover {
  box-shadow: inset 0 0 0 22px #fff;
}

.sns_button + .sns_button {
  margin: 0 0 0 12px;
}

.twitter:hover a {
  color: #1B95E0;
}
.github:hover a {
  color: #222222;
}

.note:hover a {
  color: #06f1b7;
}

.linkedin:hover a {
  color: #1B95E0;
}

.google:hover a {
  color: green;
}

.instagram:hover a {
  color: palevioletred;
}

.mail:hover a {
  color: #EE4056;
}

h2{
  font-size: 150%;
  margin-top: 3%;
  margin-bottom: 1%;
  font-weight: bold;
}

h3{
  font-size: 170%;
  padding-top: 2%;
  padding-bottom: 1%;
  font-weight: bold;
}

p {
  margin-bottom: 0.7%;
}

footer{
  text-align: right;
}

.contents{
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 3%;
}

.home-about{
  position: absolute;
  text-align: center;
  overflow: hidden;
  top: 23%;
  bottom: 3%;
  left: 50%;
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin: auto;
  min-width: 268px;
  font-size:min(5vw, 35px);
  color: #fff;
}